import React from 'react'
import config from '../../../config'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='content has-text-centered'>
          <p>
            {config.copyright}
          </p>
          <p>Made with <span className='icon'><i className='far fa-heart' /></span> by <a href='mailto:maud.miko@gmail.com'>Maud M.</a> and <a href='mailto:victor.mikolajczyk@reseau.eseo.fr'>Victor M.</a></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
