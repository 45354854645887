import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import config from '../../../config'
import './styles.sass'
import '@fortawesome/fontawesome-free/css/all.css'

const HomePageTemplate = ({
  title,
  heading,
  description,
  meta_title,
  meta_description,
}) => (
  <main className='main' role='main'>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='presentation has-text-centered'>
      <div className='container hero'>
        <figure className='image logo'>
          <img src='img/logo.svg' alt={title} />
        </figure>
        <div className='hero-body'>
          <h1 className='title'>{heading}</h1>
          <h2 className='subtitle'>{description}</h2>
        </div>
      </div>
    </section>
    <section className='reservation has-text-centered'>
      <div className='container hero'>
        <div className='hero-body'>
          <h2 className='subtitle'>En attendant retrouvez-nous et réservez le gîte sur <a href='https://www.gites-de-france.com/fr/occitanie/herault/les-loges-du-tary-34g2005' alt='Réservez sur Gîtes de France' target='_blank' rel='noopener noreferrer'>Gîtes de France</a></h2>
          <div className='columns is-centered'>
            <div className='column is-narrow'>
              <a href='https://www.gites-de-france.com/fr/occitanie/herault/les-loges-du-tary-34g2005' alt='Réservez sur Gîtes de France' target='_blank' rel='noopener noreferrer' className='partner'>
                <img id='partner-belvilla' src='https://www.gites-de-france.com/themes/custom/g2f/build/img/gites-de-france.png' alt='Logo Gîtes de France' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='contact has-text-centered'>
      <address>
        <div className='container hero'>
          <div className='hero-body'>
            <h2 className='subtitle'>Pour nous contacter plusieurs options :</h2>
            <div className='columns is-centered'>
              <div className='column is-narrow'>
                <p className='contact-type'>
                  <strong>Port: </strong>
                  <a href={'tel:' + config.port} className='is-italic'>{config.port}</a></p>
              </div>
              <div className='column is-narrow'>
                <p className='contact-type'>
                  <strong>Mail: </strong>
                  <a href={'mailto:' + config.email} className='is-italic'>{config.email}</a></p>
              </div>
            </div>
            <div className='columns is-centered'>
              <div className='column is-narrow'>
                <a href='https://g.page/les-loges-du-tary?share' target='_blank'>
                  <p>12 route d'aigne, 34210 Azillanet</p>
                </a>
              </div>
            </div>
            <div className='columns is-centered'>
              <div className='column is-narrow'>
                <p className='contact-type'>
                  <a href={'https://' + config.facebook} target='_blank' className='is-italic'>
                    <span className='icon'>
                      <i className='fab fa-facebook-square' />
                    </span>
                    facebook.com/leslogesdutary
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </address>
    </section>
    <section className='remerciement has-text-centered'>
      <div className='container hero'>
        <div className='hero-body'>
          <h2 className='subtitle'>A très vite dans le Minervois !</h2>
        </div>
      </div>
    </section>
  </main>
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
}

export default HomePageTemplate
