import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import '../../assets/sass/styles.sass'
import config from '../../../config'
import Footer from '../Footer'

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = {isActive: false}
  }

  toggleNavbar () {
    this.setState({isActive: !this.state.isActive})
  }

  render () {
    return (
      <Fragment>
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name='description' content={config.siteDescription} />
        </Helmet>
        {this.props.children}
        <Footer />
      </Fragment>
    )
  }
}

export default Layout
