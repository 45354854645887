module.exports = {
  siteTitle: 'Les loges du Tary', // Site title.
  siteTitleAlt: 'Les loges du Tary - Gîte dans le Minervois', // Alternative site title for SEO.
  siteLogo: '/icons/icon.svg', // Logo used for SEO and manifest.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Les loges du Tary - Gîte dans le Minervois', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteUrl: 'https://www.leslogesdutary.com',
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'gatsby-business-starter', // Disqus shortname.
  userName: 'Victor Mikolajczyk',
  userTwitter: 'leslogesdutary',
  userLocation: 'Azillanet, Hérault, France',
  userDescription: '',
  copyright: 'Copyright © Les loges du Tary 2019-2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#fbfbfb', // Used for setting manifest and progress theme colors.
  backgroundColor: '#fbfbfb', // Used for setting manifest background color.
  email: 'leslogesdutary@free.fr',
  port: '+33 6 08 55 28 68',
  tel: '+33 9 54 83 63 29',
  facebook: 'facebook.com/Leslogesdutary',
}
